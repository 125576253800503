<template>
  <div :id="id"></div>
</template>
<script>
// import echarts from 'echarts'

export default {
  props: ['id', 'energyData', 'totalNumber'],

  data () {
    return {
      charts: '',
      YDATA: []
    }
  },
  watch: {
    energyData: {
      deep: true,
      immediate: true,
      handler (newvalue, lodvalue) {
        this.$nextTick(() => {
          this.energyData = newvalue
          //   console.log(this.energyData, '-=')
          this.initChart(this.id)
        })
      }
    },
    totalNumber: {
      immediate: true,
      handler (newvalue, lodvalue) {
        this.$nextTick(() => {
          this.totalNumber = newvalue
          //   console.log(this.energyData, '-=')
          this.initChart(this.id)
        })
      }
    }

  },
  mounted () {
    this.$nextTick(() => {
      this.initChart(this.id)
    })
  },
  created () { },
  methods: {
    initChart (id) {
      const list = [
        {
          type: 'pie',
          radius: ['70%', '45%'],
          center: ['40%', '50%'],
          hoverAnimation: false,
          data: [
          ],
          label: {
            show: true,
            position: 'outside',
            // formatter: '{d}%',
            // formatter: (a) => {
            //   return (a.value * 100 / this.totalNumber).toFixed(2) + '%'
            // },
            formatter: (params) => {
              // console.log('此时params', params)
              if (params.name !== '') {
                return `${params.percent === undefined ? 0 : params.percent}%`
              }
              return ''
            },
            color: '#FFFFFF'
          },
          labelLine: {
            normal: {
              length: 30,
              lineStyle: {
                width: 1
              }
            }
          }
        },
        // 内圆 + 中间文字
        {
          type: 'pie',
          radius: ['45%', '25%'],
          center: ['40%', '50%'],
          hoverAnimation: false,
          z: 10,
          label: {
            position: 'center',
            formatter: () => {
              return ''
              // return '佣金总额\r\n{total|100} 个'
            },
            rich: {
              total: {
                fontSize: 24,
                color: '#FFFFFF'
              }
            },
            color: '#FFFFFF',
            fontSize: 16,
            lineHeight: 30
          },
          data: [
          ],
          labelLine: {
            show: false
          }
        }
      ]
      const colorneilist0 = ['#0060FF', '#44D7B6', '#FFA600', '#F48B70', '#DE4EDC', '#53F12C', '#37EBD9', '#CFE82A', '#2E79F1', '#F43DDE']
      const colorneilist100 = ['#00EFFE', '#6DD400', '#6DD400', '#F76A55', '#F74DF4', '#60D230', '#2EBCAE', '#BBD02D', '#3872CF', '#CF36BC']
      this.energyData.forEach((ele, index) => {
        list[0].data.push(
          {
            value: ele.value,
            name: ele.name,
            itemStyle: {
              normal: {
                color: {
                  // 完成的圆环的颜色
                  colorStops: [
                    {
                      offset: 0,
                      color: colorneilist0[index] // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: colorneilist100[index] // 100% 处的颜色
                    }
                  ]
                }
              }
            }
          }
        )
        list[1].data.push(
          {
            value: ele.value,
            name: ele.name,
            itemStyle: {
              normal: {
                color: {
                  // 完成的圆环的颜色
                  colorStops: [
                    {
                      offset: 0,
                      color: colorneilist0[index] // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: colorneilist100[index] // 100% 处的颜色
                    }
                  ]
                },
                opacity: 0.5
              }
            }
          }
        )
      })
      this.charts = this.$echarts.init(document.getElementById(id))
      window.onresize = this.charts.resize
      this.charts.setOption({
        backgroundColor: 'transparent',
        legend: {
          orient: 'vertical',
          show: true,
          right: '1%',
          y: 'center',
          icon: 'pin',
          itemGap: 10,
          textStyle: {
            color: '#FFFFFF',
            fontSize: 10,
            lineHeight: 5
          }
        },
        tooltip: {
          show: true
        },
        series: list
      })
    },
    getCirlPoint (x0, y0, r, angle) {
      const x1 = x0 + r * Math.cos((angle * Math.PI) / 180)
      const y1 = y0 + r * Math.sin((angle * Math.PI) / 180)
      return {
        x: x1,
        y: y1
      }
    }
  }
}
</script>
